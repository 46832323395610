import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: "/sim-phong-thuy",
      name: "sim-phong-thuy",
      component: () => import("@/views/simphongthuy/Home.vue"),
      meta: {
        pageTitle: "",
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: '/mua-hang',
      name: 'mua-hang',
      component: () => import('@/views/B2C.vue'),
      meta: {
        pageTitle: '',
        isRecruit: true,
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/mua-hang/hoan-thanh/:payment',
      name: 'mua-hang-hoan-thanh',
      component: () => import('@/views/B2C.vue'),
      meta: {
        pageTitle: '',
        isRecruit: true,
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: '/mua-hang/hoan-thanh/:paymentResult/:orderId/:payment',
      name: 'ket-thuc-mua-hang',
      component: () => import('@/views/B2C.vue'),
      meta: {
        pageTitle: '',
        isRecruit: true,
        breadcrumb: [
          {
            text: 'Itel',
            active: true,
          },
        ],
      },
    },
    {
      path: "/mua-hang/buoc/:step",
      name: "mua-hang-step",
      component: () => import("@/views/B2C.vue"),
      meta: {
        pageTitle: "",
        isRecruit: true,
        breadcrumb: [
          {
            text: "Itel",
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
