import Vue from 'vue'
import { ToastPlugin, ModalPlugin, } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueSweetalert2 from 'vue-sweetalert2';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import { $themeConfig } from '@themeConfig'


Vue.prototype.loading = function (type) {
  if (type) {
    document.getElementById('loading-bg').style.display = "block";
  } else {
    document.getElementById('loading-bg').style.display = "none";
  }
}


import router from './router'
import store from './store'
import App from './App.vue'

import imagePreview from 'image-preview-vue'
import 'image-preview-vue/lib/imagepreviewvue.css'

Vue.use(imagePreview)

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  confirmButtonColor: '#ed1f24',
  cancelButtonColor: '#ff7674',
};
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueSweetalert2, options)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

var numeral = require("numeral");

Vue.filter("number", function (value) {
  return numeral(value).format("0,0").replace(/,/g, ".");
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

